import { Button, Flex, Heading, Link, Text } from '@chakra-ui/react';

export function QueryParamsError() {
  return (
    <Flex
      w="100%"
      h="100vh"
      bg="#faf3f3"
      direction="column"
      align="center"
      justify="center"
    >
      <Heading textTransform="uppercase">URL inválida!</Heading>
      <Text mt="6px">
        Uma ou mais informações não foram encontradas na URL.
      </Text>
      <Button colorScheme="green" mt="24px">
        <Link href="?UsuarioId=481a0943-ceba-4faf-89cf-28ed22847b58&PacienteId=0f6067d3-26d2-44f1-8914-5cb145c28005&AgendaId=1141b39c-f6d6-4604-87e7-70432559a3d3&ConsultaId=d03860c1-2aac-406c-9a2b-5f9e44a1108f">
          Gerar URL válida
        </Link>
      </Button>
    </Flex>
  );
}
