import { api } from '@/lib';

export async function getPatientPrescription(
  idPacient: String,
  idConsulta: String,
) {
  try {
    // console.log('textCard', idPacient, idConsulta);
    if (!idPacient || !idConsulta) {
      return 'idPacient e idConsulta devem ser preenchidos.';
    }
    const { data: result } = await api.get(
      `mevo/get-Mevo?idPaciente=${idPacient}&idConsulta=${idConsulta}`,
    );

    if (
      result?.data &&
      result?.data?.exames?.length === 0 &&
      result?.data?.atestado?.length === 0 &&
      result?.data?.medicamentos?.length === 0
    ) {
      return { result, status: 'error' };
    }

    return { result, status: 'success...' };
  } catch (error: any) {
    const logErro = error?.response.data.errors[0];
    return { logErro, status: 'error' };
  }
}
