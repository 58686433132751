import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { IoAdd } from 'react-icons/io5';

import { ConsultationInfo } from './ConsultationInfo';

export function ConsultationDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const openDrawerBtn = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button
        onClick={onOpen}
        leftIcon={<Icon as={IoAdd} fontSize="24px" />}
        colorScheme="teal"
        py="24px"
        bg="brand-green.default"
      >
        Detalhes da consulta
      </Button>

      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={openDrawerBtn}
        placement="bottom"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody py="24px">
            <ConsultationInfo />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
