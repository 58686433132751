import { usePatientFormContext } from '@/contexts';
import {
  Box,
  useRadio,
  useRadioGroup,
  Grid,
  GridItem,
  Text,
  Flex,
  Button,
  Textarea,
  List,
  InputGroup,
  Input,
  VStack,
  InputRightElement,
  useToast,
  IconButton,
  Icon,
  ListItem,
} from '@chakra-ui/react';
import {
  useCIDQuery,
  useFormInfoQuery,
  useHeaderInfoQuery,
  useScheduleMutation,
} from '@/hooks';
import { PatientFormFields } from '@/types';
import { ChangeEvent, useEffect, useState } from 'react';
import { IoSearchOutline, IoTrash } from 'react-icons/io5';
import { api } from '@/lib';
import { Prescription } from '../PatientForm/Prescription';
import PrintPdf from '../PatientForm/PrintPdf';
import {
  RadioInput,
  TagInput,
  TextAreaInput,
  TextInput,
} from '../PatientForm/Inputs';

type CurrentCID = {
  descricao: string;
  codCid: string;
};

function CardItem(props: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const { children, validate } = props;

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />

      <Box
        {...checkbox}
        cursor="pointer"
        fontSize="lg"
        fontWeight="500"
        _checked={{
          bg: 'transparent',
          color: '#60C2B0',
        }}
        color={
          (children === 'CIDS' || children === 'CONDUTA') && !validate
            ? 'red'
            : '#000'
        }
        fontFamily="Rubik"
        px={5}
        py={3}
        borderBottom="1px solid #E4E4E4"
      >
        {children}
      </Box>
    </Box>
  );
}

export default function InputAnamneseCard() {
  const toast = useToast({
    duration: 4000,
    isClosable: true,
    position: 'top-right',
  });
  const { data: defaultFormValues } = useFormInfoQuery();
  const { data: infos } = useHeaderInfoQuery();
  const { mutate, isLoading } = useScheduleMutation();
  const [validate, setValidate] = useState(true);
  const [option, setOption] = useState('EVOLUÇÃO');
  const options = [
    'QUEIXA',
    'EVOLUÇÃO',
    'ORIENTAÇÃO',
    'CONDUTA',
    'CIDS',
    'ALERGIAS',
    'ANTECEDENTES PESSOAIS',
    'ANTECEDENTES FAMILIARES',
  ];
  const [inputFocus, setInputFocus] = useState(false);
  const [CIDsInput, setCIDsInput] = useState<CurrentCID[] | any>([]);
  const [CIDsSelected, setSelected] = useState<CurrentCID[] | any>([]);
  const [allergies, setAllergies] = useState<any[] | any>([]);
  const [personalDiagnosis, setPersonalDiagnosis] = useState<any[] | any>([]);
  const [personalSurgeries, setPersonalSurgeries] = useState<any[] | any>([]);
  const [familyDiagnosis, setFamilyDiagnosis] = useState<any[] | any>([]);
  const [familySurgeries, setFamilySurgeries] = useState<any[] | any>([]);
  const [hasValueDigitized, setHasValueDigitized] = useState(false);
  const [form, setForm] = useState({
    evolution: '',
    orientation: '',
    condut: '',
    prescription: '',
    queixa: '',
    cids: [],
  });

  function SetTextForm(event: ChangeEvent<HTMLTextAreaElement>): void {
    const text = event.target.value;

    if (option === 'EVOLUÇÃO') {
      setForm({ ...form, evolution: text });
    }
    if (option === 'ORIENTAÇÃO') {
      setForm({ ...form, orientation: text });
    }
    if (option === 'CONDUTA') {
      setForm({ ...form, condut: text });
    }
    if (option === 'QUEIXA') {
      setForm({ ...form, queixa: text });
    }
  }

  function handleRemoveCid(cid: any) {
    setSelected((prevState: any) => prevState.filter((c: any) => c !== cid));
  }

  const changeCidSelect = async (e: any) => {
    if (!e.target.value) return;

    const c = await api
      .get(`/anamnese/Cid-Nome?nome=${e.target.value}`)
      .then((u: any) => {
        if (u.data.data.length > 0) {
          // const cidsFiltered = u.data.data.filter((cid: any) =>
          //   cid.descricao.toLowerCase().includes(e.target.value.toLowerCase()),
          // );
          // console.log(cidsFiltered);
          setCIDsInput(u.data.data);
        } else {
          setCIDsInput([]);
          setHasValueDigitized(true);
        }
      })
      .catch(u => console.log(u));

    console.log('cid', c);
    if (!e.target.value) {
      setCIDsInput([]);
      setHasValueDigitized(false);
    }
  };

  const selectCID = (cid: CurrentCID) => () => {
    CIDsSelected.push(cid);
    setInputFocus(false);
  };

  const SetOptionValue = (v: any) => {
    setOption(v);
  };

  function formatPayload() {
    if (CIDsSelected.length > 0 && form.condut) {
      const payload = {
        dataAtendimento: defaultFormValues?.dataAtendimento as string,
        dataAgendamento: defaultFormValues?.dataAgendamento as string,
        modalUrlMevo: defaultFormValues?.modalUrlMevo as string,
        duracao: form.queixa,
        conduta: form.condut,
        orientacao: form.orientation,
        evolucao: form.evolution,
        alergica: allergies.map((allergy: any) => ({
          descricao: allergy,
          status: true,
        })),
        antecedentesPessoais: {
          diagnosticos: personalDiagnosis.map((personalDiagnostic: any) => ({
            descricao: personalDiagnostic,
            status: true,
          })),
          cirurgias: personalSurgeries.map((personalSurgery: any) => ({
            descricao: personalSurgery,
            status: true,
          })),
        },
        antecedentesFamiliares: {
          diagnosticos: familyDiagnosis.map((familyDiagnostic: any) => ({
            descricao: familyDiagnostic,
            status: true,
          })),
          cirurgias: familySurgeries.map((familySurgery: any) => ({
            descricao: familySurgery,
            status: true,
          })),
        },
        cid: CIDsSelected.map((cid: any) => ({
          codCid: cid.codCid,
          descricao: cid.descricao,
          status: true,
        })),
      };
      return payload;
    }

    setValidate(false);
    toast({
      status: 'error',
      title: 'Ocorreu um erro. Tente novamente.',
    });
    return null;
  }

  const onSubmit = async () => {
    if (CIDsSelected.length === 0 || !form.condut) {
      setValidate(false);
      return toast({
        status: 'error',
        title: 'Insira todos os campos obrigatórios.',
      });
    }
    if (CIDsSelected.length > 0 && form.condut) {
      const payload = formatPayload();
      if (payload) mutate(payload);
    }

    return setValidate(true);
  };

  const printRelatorio = () => {
    if (CIDsSelected.length === 0 || !form.condut) {
      setValidate(false);
      return toast({
        status: 'error',
        title: 'Insira todos os campos obrigatórios.',
      });
    }
    onSubmit();
    const payload = formatPayload();
    PrintPdf(payload, infos);

    return setValidate(true);
  };

  const endConsultaApi = async () => {
    await api
      .put(
        '/consulta/consulta-status',
        {},
        {
          params: {
            idHorasAgenda: infos?.schedule.idHorasAgenda,
            statusHoraAgenda: 6,
          },
        },
      )
      .then(res => {
        toast({ title: 'Consulta encerrada', status: 'success' });
        window.location.href = 'https://portal.dmhealth.com.br/app/start';
      })
      .catch(err => {
        toast({ title: err.response.data.errors[0], status: 'error' });
      });
  };

  const endConsulta = () => {
    if (CIDsSelected.length === 0 || !form.condut) {
      setValidate(false);
      return toast({
        status: 'error',
        title: 'Insira todos os campos obrigatórios.',
      });
    }
    const payload = formatPayload();
    if (CIDsSelected.length > 0 && form.condut) {
      if (payload) mutate(payload);
      endConsultaApi();
    }

    return setValidate(true);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    defaultValue: 'EVOLUÇÃO',
    onChange: SetOptionValue,
  });

  return (
    <Box height="100%">
      <Grid width="100%" templateColumns="repeat(12, 1fr)" height="80%">
        <GridItem colSpan={4} paddingTop="5">
          {options.map(value => {
            const radio = getRadioProps({ value });
            return (
              <CardItem
                key={value}
                {...radio}
                defaultValue="EVOLUÇÃO"
                validate={validate}
              >
                {value}
              </CardItem>
            );
          })}
        </GridItem>
        <GridItem display="flex" colSpan={8}>
          <Box
            backgroundColor="#fff"
            width="100vw"
            border="1px solid #E4E4E4"
            borderRadius="10"
          >
            {option === 'CIDS' ? (
              <VStack
                backgroundColor="#fff"
                spacing="18px"
                w="100%"
                p="15"
                resize="none"
                color="#000"
                fontFamily="Rubik"
                sx={{
                  '&::-webkit-scrollbar': { width: '6px' },
                  '&::-webkit-scrollbar-track': { borderRadius: '8px' },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '15',
                    bg: '#bfbfbf',
                  },
                }}
              >
                <InputGroup mb="8px">
                  <Input
                    color="#000"
                    type="text"
                    placeholder="Insira o CID"
                    onChange={changeCidSelect}
                    // value={namePatient}
                    onFocus={() => setInputFocus(true)}
                  />
                  <InputRightElement pointerEvents="none" />
                </InputGroup>
                {CIDsInput && CIDsInput.length && inputFocus ? (
                  <List
                    mb="10"
                    h="100%"
                    backgroundColor="#fff"
                    style={{
                      maxHeight: '20rem',
                      overflowY: 'scroll',
                      width: '100%',
                      height: '100%',
                    }}
                    sx={{
                      '&::-webkit-scrollbar': { width: '6px' },
                      '&::-webkit-scrollbar-track': { borderRadius: '8px' },
                      '&::-webkit-scrollbar-thumb': {
                        borderRadius: '15',
                        bg: '#bfbfbf',
                      },
                    }}
                  >
                    {CIDsInput.map((c: any) => (
                      <Flex
                        style={{ cursor: 'pointer' }}
                        justifyContent="space-between"
                        key={c.id}
                        onClick={selectCID(c)}
                      >
                        <Flex
                          p="2"
                          flexDirection="column"
                          border="1px solid #E4E4E4"
                          w="100%"
                          borderRadius="10"
                          mb="5"
                        >
                          <Text
                            fontSize="sm"
                            w="100%"
                            color="#616161"
                            fontFamily="Rubik"
                          >
                            Código: {c.codCid}
                          </Text>
                          <Text
                            fontSize="sm"
                            w="100%"
                            color="#616161"
                            fontFamily="Rubik"
                          >
                            Descrição: {c.descricao}
                          </Text>
                        </Flex>
                      </Flex>
                    ))}
                  </List>
                ) : (
                  <Box
                    justifyContent="space-between"
                    width="100%"
                    overflowY="auto"
                    borderRadius="15"
                    maxH="20%"
                    sx={{
                      '&::-webkit-scrollbar': { width: '6px' },
                      '&::-webkit-scrollbar-track': { borderRadius: '8px' },
                      '&::-webkit-scrollbar-thumb': {
                        borderRadius: '15',
                        bg: '#bfbfbf',
                      },
                    }}
                  >
                    <List spacing="10px" w="100%" listStyleType="none">
                      {CIDsSelected.length > 0 &&
                        CIDsSelected.map((cid: any) => (
                          <ListItem key={cid} as={Flex} w="100%" gap="8px">
                            <Box
                              py="12px"
                              px="38px"
                              w="100%"
                              borderRadius="8px"
                              bg="brand-green.darkened"
                              color="white"
                              fontSize="10px"
                              fontWeight="600"
                              textTransform="uppercase"
                            >
                              {cid.descricao}
                            </Box>
                            <IconButton
                              onClick={() => handleRemoveCid(cid)}
                              aria-label="Remover CID"
                              icon={
                                <Icon
                                  as={IoTrash}
                                  color="red.500"
                                  fontSize="16px"
                                />
                              }
                              borderRadius="12px"
                              bg="white"
                              boxShadow="2px 2px 7px -3px rgba(91, 93, 115, 0.25)"
                            />
                          </ListItem>
                        ))}
                    </List>
                  </Box>
                )}
              </VStack>
            ) : option === 'ALERGIAS' ? (
              <VStack
                backgroundColor="#fff"
                spacing="18px"
                w="100%"
                p="15"
                resize="none"
                color="#000"
                fontFamily="Rubik"
                sx={{
                  '&::-webkit-scrollbar': { width: '6px' },
                  '&::-webkit-scrollbar-track': { borderRadius: '8px' },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '15',
                    bg: '#bfbfbf',
                  },
                }}
              >
                <TagInput
                  name="alergias"
                  label="Alergia(s)"
                  values={allergies}
                  setValues={setAllergies}
                />
              </VStack>
            ) : option === 'ANTECEDENTES PESSOAIS' ? (
              <VStack
                backgroundColor="#fff"
                spacing="18px"
                w="100%"
                p="15"
                resize="none"
                color="#000"
                fontFamily="Rubik"
                sx={{
                  '&::-webkit-scrollbar': { width: '6px' },
                  '&::-webkit-scrollbar-track': { borderRadius: '8px' },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '15',
                    bg: '#bfbfbf',
                  },
                }}
              >
                <VStack spacing="18px" w="100%">
                  <Text
                    fontSize="15px"
                    fontWeight="500"
                    alignSelf="start"
                    color="#0008"
                    fontFamily="Rubik"
                    textTransform="uppercase"
                  >
                    Antecedentes Pessoais
                  </Text>

                  <TagInput
                    name="diagnosticosPessoais"
                    label="Diagnóstico(s)"
                    values={personalDiagnosis}
                    setValues={setPersonalDiagnosis}
                  />

                  <TagInput
                    name="cirurgiasPessoais"
                    label="Cirurgia(s)"
                    values={personalSurgeries}
                    setValues={setPersonalSurgeries}
                  />
                </VStack>
              </VStack>
            ) : option === 'ANTECEDENTES FAMILIARES' ? (
              <VStack
                backgroundColor="#fff"
                spacing="18px"
                w="100%"
                p="15"
                resize="none"
                color="#000"
                fontFamily="Rubik"
                sx={{
                  '&::-webkit-scrollbar': { width: '6px' },
                  '&::-webkit-scrollbar-track': { borderRadius: '8px' },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '15',
                    bg: '#bfbfbf',
                  },
                }}
              >
                <VStack spacing="18px" w="100%">
                  <Text
                    fontSize="15px"
                    fontWeight="500"
                    alignSelf="start"
                    color="#0008"
                    fontFamily="Rubik"
                    textTransform="uppercase"
                  >
                    Antecedentes Familiares
                  </Text>

                  <TagInput
                    name="diagnosticosFamiliares"
                    label="Diagnóstico(s)"
                    values={familyDiagnosis}
                    setValues={setFamilyDiagnosis}
                  />

                  <TagInput
                    name="cirurgiasFamiliares"
                    label="Cirurgia(s)"
                    values={familySurgeries}
                    setValues={setFamilySurgeries}
                  />
                </VStack>
              </VStack>
            ) : (
              <Textarea
                placeholder={`ADICIONE A ${option}`}
                height="100%"
                resize="none"
                color="#000"
                fontFamily="Rubik"
                sx={{
                  '&::-webkit-scrollbar': { width: '6px' },
                  '&::-webkit-scrollbar-track': { borderRadius: '8px' },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '15',
                    bg: '#bfbfbf',
                  },
                }}
                onChange={SetTextForm}
                value={
                  option === 'EVOLUÇÃO'
                    ? form.evolution
                    : option === 'ORIENTAÇÃO'
                    ? form.orientation
                    : option === 'CONDUTA'
                    ? form.condut
                    : option === 'QUEIXA'
                    ? form.queixa
                    : ''
                }
              />
            )}
          </Box>
        </GridItem>
      </Grid>

      <Flex
        mt="responsive"
        p="20px"
        gap="24px"
        wrap="wrap"
        align="flex-end"
        justify="flex-end"
        top="200"
      >
        <Button
          type="button"
          disabled={isLoading}
          variant="outline"
          colorScheme="teal"
          py="12px"
          px="64px"
          w={['100%', 'auto']}
          borderRadius="20px"
          color="#9F9F9F"
          fontSize="xs"
          fontWeight="600"
          textTransform="uppercase"
          _hover={{ bg: '#9F9F9F', color: 'white' }}
          onClick={printRelatorio}
        >
          Imprimir
        </Button>
        <Button
          type="button"
          disabled={isLoading}
          variant="outline"
          colorScheme="teal"
          py="12px"
          px="64px"
          w={['100%', 'auto']}
          borderRadius="20px"
          color="#9F9F9F"
          fontSize="xs"
          fontWeight="600"
          textTransform="uppercase"
          _hover={{ bg: '#9F9F9F', color: 'white' }}
          onClick={endConsulta}
        >
          Encerrar
        </Button>
        <Button
          type="button"
          disabled={isLoading}
          variant="solid"
          colorScheme="teal"
          bg="brand-green.default"
          py="12px"
          px="64px"
          w={['100%', 'auto']}
          borderRadius="20px"
          color="white"
          fontSize="xs"
          fontWeight="600"
          textTransform="uppercase"
          _hover={{ bg: 'brand-green.default', color: 'white' }}
          onClick={onSubmit}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  );
}
