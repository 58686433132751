import { Button, Flex, Heading, Link, Text } from '@chakra-ui/react';
import { useState } from 'react';

interface Props {
  title: string;
  text: string;
  desc?: string;
}

export function ConsultationClosed({ title, text, desc }: Props) {
  const [showButton, setShowButton] = useState(false);

  setTimeout(() => setShowButton(true), 5000);

  return (
    <Flex
      w="100%"
      h="100vh"
      bg="#faf3f3"
      direction="column"
      align="center"
      justify="center"
    >
      <Heading textTransform="uppercase">{title}</Heading>
      <Text mt="6px">{text}</Text>
      {desc && (
        <Text mt="6px" align="center">
          {desc}
        </Text>
      )}
      {showButton && (
        <Button
          type="button"
          colorScheme="teal"
          mt="12px"
          py="12px"
          px="20px"
          w={['100%', 'auto']}
          borderRadius="20px"
          bg="brand-green.default"
          fontSize="xs"
          fontWeight="600"
          textTransform="uppercase"
          onClick={() => window.location.reload()}
        >
          Recarregar página
        </Button>
      )}
    </Flex>
  );
}
