import {
  usePrescriptionQuery,
  getDataPatientConsulta,
} from '@/hooks/queries/usePrescriptionQuery';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useQueryParams } from '@/hooks';
import { PrescriptionModal } from './Modals/PrescriptionModal';
import { PostPrescription } from './Api/PostPrescription';

export function Prescription({ expand }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: infos, refetch, isLoading } = usePrescriptionQuery();
  const [url, setUrl] = useState();
  const userData = getDataPatientConsulta();

  useEffect(() => {
    setUrl(infos?.modalURL);
  }, [infos]);

  // useEffect(() => console.log(isLoading));

  useEffect(() => {
    // console.log(url);
    window.addEventListener(
      'message',
      event => {
        // console.log(event.data.type);
        PostPrescription(event.data, userData);
        const validEvents = ['cancel', 'excluded', 'prescricao'];
        if (validEvents.includes(event.data.type)) {
          if (event.data.type === 'prescricao') {
            onOpen();
          }
        }
      },
      false,
    );
  }, [userData]);

  return (
    <Flex p="20px" direction="column" flex="1">
      <Flex
        w="100%"
        h="100%"
        bg="gray.200"
        align="center"
        justify="center"
        flex="1"
        display="block"
        position="relative"
      >
        <iframe
          height={expand === 4 ? '700px' : '500px'}
          width="100%"
          id="idframe"
          src={url}
          title="prescriçao"
        />

        {isOpen ? (
          <PrescriptionModal
            isOpen={isOpen}
            onClose={onClose}
            refetch={refetch}
            isLoading={isLoading}
          />
        ) : null}
      </Flex>
    </Flex>
  );
}
