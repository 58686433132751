import { Box, Flex, Image, useBreakpointValue } from '@chakra-ui/react';

import { ConsultationInfo } from '@/components/Header/ConsultationInfo';
import { ConsultationDrawer } from '@/components/Header/ConsultationDrawer';

import logoImg from '@/assets/logo.svg';

export function Header() {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  return (
    <Flex
      as="header"
      direction={['column', 'row']}
      align="center"
      justify="space-between"
      pt="28px"
      px="28px"
      pb="10px"
      w="100%"
    >
      {/* <Image mb={['24px', 0]} src={logoImg} alt="Logo da DMH" /> */}

      {isWideVersion ? (
        <Box ml="72px" flex="1">
          <ConsultationInfo />
        </Box>
      ) : (
        <ConsultationDrawer />
      )}
    </Flex>
  );
}
