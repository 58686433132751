import { Provider } from '@/providers';

import { useQueryParams } from '@/hooks';

import { Main } from '@/pages';
import { PageLoading, QueryParamsError } from '@/components';
import { api } from './lib';

function App() {
  const queryParams = useQueryParams();

  function renderContent() {
    if (queryParams === undefined) {
      return <PageLoading />;
    }

    if (queryParams === null) {
      return <QueryParamsError />;
    }

    return <Main />;
  }

  return <Provider>{renderContent()}</Provider>;
}

export default App;
