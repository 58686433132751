import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  SimpleGrid,
  Text,
  Icon,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BiCheck, BiCheckCircle, BiX } from 'react-icons/bi';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  refetch?: any;
  isLoading: any;
}

const URLPortal = 'https://staging-embedded.nexodata.com.br/v3/prescription/';

export function PrescriptionModal({
  isOpen,
  onClose,
  refetch,
  isLoading,
}: Props) {
  const newPrescription = () => {
    refetch();
    onClose();
  };

  return (
    // <Modal
    //   isOpen={isOpen}
    //   onClose={onClose}
    //   size="md"
    //   isCentered
    //   closeOnOverlayClick={false}
    //   closeOnEsc={false}
    // >
    //   <ModalOverlay />
    //   <ModalContent p="8" borderRadius="20px">
    //     <ModalCloseButton />
    //     <ModalBody pb="8">
    //       <Text mb="6" color="brand-dark.200" fontSize="sm" align="center">
    //         <Text as="strong">Prescrição realizada com sucesso</Text>
    //       </Text>
    //       <SimpleGrid
    //         minChildWidth="120px"
    //         spacing="4"
    //         w="100%"
    //         marginTop="40px"
    //       >
    //         <Button
    //           type="button"
    //           variant="outline"
    //           colorScheme="brand-orange"
    //           h="45px"
    //           borderRadius="28px"
    //           color="brand-orange.300"
    //           fontSize="xs"
    //           fontWeight="medium"
    //           textTransform="uppercase"
    //           onClick={newPrescription}
    //         >
    //           Nova Prescrição
    //         </Button>

    //         <Button
    //           type="button"
    //           variant="outline"
    //           colorScheme="brand-orange"
    //           h="45px"
    //           borderRadius="28px"
    //           color="brand-orange.300"
    //           fontSize="xs"
    //           fontWeight="medium"
    //           textTransform="uppercase"
    //         >
    //           Finalizar Prescrição
    //         </Button>
    //       </SimpleGrid>
    //     </ModalBody>
    //   </ModalContent>
    // </Modal>

    // <Modal
    //   isOpen={isOpen}
    //   onClose={onClose}
    //   size="md"
    //   isCentered
    //   closeOnOverlayClick={false}
    //   closeOnEsc={false}
    // >
    //   <ModalOverlay />
    //   <ModalContent p="10" borderRadius="20px">
    //     <ModalBody pb="8">
    <Flex alignContent="center" alignItems="center">
      <Box
        background="#fff"
        borderRadius="25px"
        box-shadow="0 2px 8px #333"
        min-width="320px"
        minHeight="250px"
        position="absolute"
        left="50%"
        padding="24px"
        top="50%"
        transform="translate(-50%, -50%)"
        alignItems="center"
      >
        <VStack spacing="4" w="100%" alignItems="center">
          <Icon
            as={BiCheckCircle}
            fontSize="80px"
            color="brand-green.default"
          />
        </VStack>
        <Heading
          mt="6"
          mb="4"
          color="brand-dark.300"
          fontSize="xl"
          fontWeight="medium"
        >
          <Text color="text.lightened" fontSize="2xl" fontWeight="medium">
            Prescrição realizada com sucesso{' '}
          </Text>
        </Heading>
        <VStack spacing="4" w="100%" marginTop="40px">
          <SimpleGrid minChildWidth="120px" spacing="4" w="100%">
            <Button
              type="button"
              isLoading={isLoading}
              colorScheme="teal"
              py="12px"
              px="64px"
              w={['100%', 'auto']}
              borderRadius="20px"
              bg="brand-green.default"
              fontSize="xs"
              fontWeight="600"
              textTransform="uppercase"
              onClick={newPrescription}
            >
              Nova Prescrição
            </Button>
          </SimpleGrid>
        </VStack>
      </Box>
    </Flex>
    //     </ModalBody>
    //   </ModalContent>
    // </Modal>
  );
}
