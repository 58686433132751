import { ChakraProvider } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { theme } from '@/styles';

interface UIProviderProps {
  children: ReactNode;
}

export function UIProvider({ children }: UIProviderProps) {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
}
