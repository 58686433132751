import { useQuery } from 'react-query';

import { useQueryParams } from '@/hooks';
import { api } from '@/lib';

async function getPrescription({ consultationId, patientId, userId }: any) {
  const { data } = await api.get<any>(
    `/mevo/prescricao?MedicoId=${userId}&PacienteId=${patientId}&ConsultaId=${consultationId}`,
  );

  return data?.data;
}

export function getDataPatientConsulta() {
  const params = useQueryParams();
  return params;
}

export function usePrescriptionQuery() {
  const params = useQueryParams();
  const consultationId = params?.ConsultaId as string;
  const patientId = params?.PacienteId as string;
  const userId = params?.UsuarioId as string;

  const query = useQuery(
    ['Prescription', consultationId, patientId, userId],
    () => getPrescription({ consultationId, patientId, userId }),
    {
      enabled: !!params,
    },
  );

  return query;
}
