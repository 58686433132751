import { useQueryParams } from '@/hooks';
import { api } from '@/lib';
import { GetFormInfoParams, GetFormInfoParamsResponse } from '@/types';
import { useQuery } from 'react-query';

async function getFormInfo({ medicId, patientId }: GetFormInfoParams) {
  const { data } = await api.get<GetFormInfoParamsResponse>(
    `/anamnese?idMedico=${medicId}&idPaciente=${patientId}`,
  );

  return {
    dataAtendimento: data.data?.dataAtendimento ?? new Date().toISOString(),
    dataAgendamento: data.data?.dataAgendamento ?? new Date().toISOString(),
    modalUrlMevo: data.data?.modalUrlMevo ?? 'https://reactconf.com.br/',
    allergies: data.data?.alergica.map(({ descricao }) => descricao) ?? [],
    cids: data.data?.cid.map(({ descricao }) => descricao) ?? [],
    conduct: data.data?.conduta ?? '',
    duration: data.data?.duracao ?? '',
    evolution: data.data?.evolucao ?? '',
    familyHistory: {
      diagnoses:
        data.data?.antecedentesFamiliares.diagnosticos.map(
          ({ descricao }) => descricao,
        ) ?? [],
      surgeries:
        data.data?.antecedentesFamiliares.cirurgias.map(
          ({ descricao }) => descricao,
        ) ?? [],
    },
    orientation: data.data?.orientacao ?? '',
    personalHistory: {
      diagnoses:
        data.data?.antecedentesPessoais.diagnosticos.map(
          ({ descricao }) => descricao,
        ) ?? [],
      surgeries:
        data.data?.antecedentesPessoais.cirurgias.map(
          ({ descricao }) => descricao,
        ) ?? [],
    },
  };
}

export function useFormInfoQuery() {
  const params = useQueryParams();
  const medicId = params?.UsuarioId as string;
  const patientId = params?.PacienteId as string;

  const query = useQuery(
    ['formInfo', medicId, patientId],
    () => getFormInfo({ medicId, patientId }),
    {
      enabled: !!params,
    },
  );

  return query;
}
