import { api } from '@/lib';
import { mapPrescription } from '@/hooks/utils/mapPrescription';
import { getDataPatientConsulta } from '@/hooks/queries/usePrescriptionQuery';

export async function PostPrescription(data: any, userData: any) {
  const { PacienteId, ConsultaId } = userData;

  try {
    let dadosMod = {};
    if (data) {
      dadosMod = mapPrescription(data, PacienteId, ConsultaId);
    }

    const resp = await api.post('/mevo/post-Mevo', dadosMod);

    return { data, status: 'success' };
  } catch (error) {
    return { error, status: 'error' };
  }
}
