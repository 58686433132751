import { getAnamneseMevo } from '@/hooks/mutations/getConsultas';
import { getPatientPrescription } from '@/hooks/mutations/getPatientPrescription';
import {
  Box,
  useRadio,
  useRadioGroup,
  Grid,
  GridItem,
  Text,
  Icon,
} from '@chakra-ui/react';

import { useEffect, useState } from 'react';
import { IoArrowBackOutline } from 'react-icons/io5';
import { HistoricModal } from './HistoricModal/HistoricModal';

function CardItem(props: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const { children } = props;

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />

      <Box
        {...checkbox}
        cursor="pointer"
        fontSize="lg"
        fontWeight="500"
        _checked={{
          bg: 'transparent',
          color: '#60C2B0',
        }}
        color="#000"
        fontFamily="Rubik"
        px={5}
        py={3}
        borderBottom="1px solid #E4E4E4"
      >
        {children}
      </Box>
    </Box>
  );
}

export default function RadioCard({ dados, onPress }: any) {
  const [textCard, setTextCard] = useState<any[]>([]);
  const [anamnese, setAnamnese] = useState<any>();
  const [option, setOption] = useState('PRESCRIÇÃO');
  const [prescription, setPrescription] = useState<any>([]);
  const options = ['PRESCRIÇÃO', 'EVOLUÇÃO', 'ORIENTAÇÃO', 'CONDUTA', 'CIDS'];
  const date = new Date(dados.horasAgenda.agenda.data ?? '').toLocaleDateString(
    'pt-BR',
  );

  async function getDataPrescription() {
    if (anamnese?.anamnese) {
      const { pacienteId: idPaciente, consultaId: idConsulta } =
        anamnese.anamnese;

      const resp = await getPatientPrescription(idPaciente, idConsulta);
      if (resp) {
        setPrescription(resp);
      }
    }
  }

  const SetOptionValue = (v: any) => {
    setOption(v);

    setTextCard([]);

    if (v === 'PRESCRIÇÃO') {
      setTextCard([]);
    }
    if (v === 'EVOLUÇÃO' && anamnese?.anamnese?.evolucao) {
      setTextCard([anamnese?.anamnese?.evolucao] || '');
    }
    if (v === 'ORIENTAÇÃO' && anamnese?.anamnese?.orientacao) {
      setTextCard([anamnese?.anamnese?.orientacao] || '');
    }
    if (v === 'CONDUTA' && anamnese?.anamnese?.conduta) {
      setTextCard([anamnese?.anamnese?.conduta] || '');
    }
    if (v === 'CIDS' && anamnese?.anamnese?.cid) {
      const c = anamnese?.anamnese?.cid.map((cid: any) => cid?.descricao);
      setTextCard(c);
    }
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    defaultValue: 'PRESCRIÇÃO',
    onChange: SetOptionValue,
  });

  useEffect(() => {
    setPrescription([]);
    if (anamnese) {
      setTextCard([]);
      getDataPrescription();
      SetOptionValue(option);
    }
  }, [anamnese]);

  const GetAnamnesesMevo = async () => {
    if (dados) {
      const data = await getAnamneseMevo(dados.id, dados.pacienteId);
      setAnamnese(data);
    }
  };

  useEffect(() => {
    GetAnamnesesMevo();
  }, [dados]);

  return (
    <Box>
      <Grid width="100%" templateColumns="repeat(12, 1fr)">
        <GridItem colSpan={2} paddingTop="5">
          <Text
            fontSize="sm"
            w="100%"
            color="#3D3D3D"
            fontFamily="Rubik"
            paddingBottom="19"
            display="flex"
          >
            <Icon
              as={IoArrowBackOutline}
              fontSize="24px"
              marginRight={5}
              onClick={onPress}
              cursor="pointer"
            />
            {date}
          </Text>
          {options.map(value => {
            const radio = getRadioProps({ value });
            return (
              <CardItem key={value} {...radio} defaultValue="PRESCRIÇÃO">
                {value}
              </CardItem>
            );
          })}
        </GridItem>
        <GridItem display="flex" colSpan={10}>
          <Box
            backgroundColor="#fff"
            width="100vw"
            border="1px solid #E4E4E4"
            borderRadius="10"
          >
            {option === 'PRESCRIÇÃO' && prescription?.result ? (
              <Box
                style={{
                  maxHeight: '30rem',
                  height: '30rem',
                  borderRadius: '10px',
                  backgroundColor: '#fff',
                }}
              >
                <HistoricModal prescription={prescription} />
              </Box>
            ) : (
              <Box
                style={{
                  height: 'auto',
                  borderRadius: '10px',
                  backgroundColor: '#fff',
                  overflowY: 'auto',
                  color: '#0005',
                }}
              >
                {textCard.length ? (
                  textCard?.map((p: any) => (
                    <Text fontFamily="Rubik" padding="5">{`- ${p}`}</Text>
                  ))
                ) : (
                  <Text fontFamily="Rubik" padding="5">
                    NÃO HÁ DADOS
                  </Text>
                )}
              </Box>
            )}
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}
