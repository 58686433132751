import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import logoImg from '@/assets/logo.svg';

function PrintPdf(relatorio: any, infos: any) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportTitle: any = [
    {
      text: 'Relatório da Anamnese \n\n',
      fontSize: 18,
      bold: true,
      alignment: 'center',
      margin: [0, 20, 0, 120],
    },
  ];

  const cids = relatorio?.cid?.map((cid: any) => {
    return [{ text: cid.descricao, fontSize: 15, margin: [0, 2, 0, 2] }];
  });

  const alergias = relatorio?.alergica.map((alergia: any) => {
    return [{ text: alergia.descricao, fontSize: 15, margin: [0, 2, 0, 2] }];
  });

  const diagnosticosFam = relatorio?.antecedentesFamiliares?.diagnosticos?.map(
    (diagnosticos: any) => {
      return [
        { text: diagnosticos.descricao, fontSize: 15, margin: [0, 2, 0, 2] },
      ];
    },
  );

  const diagnosticosPes = relatorio?.antecedentesPessoais?.diagnosticos?.map(
    (diagnosticos: any) => {
      return [
        { text: diagnosticos.descricao, fontSize: 15, margin: [0, 2, 0, 2] },
      ];
    },
  );

  const cirurgiasFam = relatorio?.antecedentesFamiliares?.cirurgias?.map(
    (cirurgias: any) => {
      return [
        { text: cirurgias.descricao, fontSize: 15, margin: [0, 2, 0, 2] },
      ];
    },
  );

  const cirurgiasPes = relatorio?.antecedentesPessoais?.cirurgias?.map(
    (cirurgias: any) => {
      return [
        { text: cirurgias.descricao, fontSize: 15, margin: [0, 2, 0, 2] },
      ];
    },
  );

  const details: any = [
    {
      text: [
        'Paciente: ',
        { text: infos?.patient.name, fontSize: 15, bold: false },
      ],
      fontSize: 15,
      bold: true,
      margin: [15, 10, 0, 0],
    },
    {
      text: [
        'Idade: ',
        { text: infos?.patient.age, fontSize: 15, bold: false },
      ],
      fontSize: 15,
      bold: true,
      margin: [15, 10, 0, 0],
    },
    {
      text: [
        'Sexo: ',
        { text: infos?.patient.gender, fontSize: 15, bold: false },
      ],
      fontSize: 15,
      bold: true,
      margin: [15, 10, 0, 0],
    },

    {
      text: [
        'Profissional: ',
        { text: infos?.medic.name, fontSize: 15, bold: false },
      ],
      fontSize: 15,
      bold: true,
      margin: [15, 10, 0, 0],
    },
    {
      text: 'Dados gerais da consulta',
      fontSize: 18,
      bold: true,
      margin: [15, 40, 0, 0],
      alignment: 'center',
    },
    {
      text: `Queixa/Duração:`,
      fontSize: 15,
      bold: true,
      margin: [15, 30, 0, 0],
    },
    {
      ul: [relatorio?.duracao],
      fontSize: 15,
      margin: [20, 10, 0, 10],
    },
    {
      text: `Evolução:`,
      fontSize: 15,
      bold: true,
      margin: [15, 10, 0, 10],
    },
    {
      ul: [relatorio?.evolucao],
      fontSize: 15,
      margin: [20, 10, 0, 10],
    },
    {
      text: `Alergias: `,
      fontSize: 15,
      bold: true,
      margin: [15, 10, 0, 0],
    },
    {
      fontSize: 15,
      margin: [20, 10, 0, 10],
      ul: [...alergias],
    },

    {
      text: 'Antecedentes Pessoais',
      fontSize: 15,
      bold: true,
      margin: [15, 20, 0, 10],
    },
    {
      text: `Diagnósticos: `,
      fontSize: 15,
      margin: [25, 5, 0, 0],
    },
    {
      fontSize: 15,
      margin: [30, 5, 0, 10],
      ul: [...diagnosticosPes],
    },
    {
      text: `Cirurgias: `,
      fontSize: 15,
      margin: [25, 5, 0, 0],
    },
    {
      fontSize: 15,
      margin: [30, 5, 0, 10],
      ul: [...cirurgiasPes],
    },
    {
      text: 'Antecedentes Familiares',
      fontSize: 15,
      bold: true,
      margin: [15, 20, 0, 10],
    },
    {
      text: `Diagnósticos: `,
      fontSize: 15,
      margin: [25, 5, 0, 0],
    },
    {
      fontSize: 15,
      margin: [30, 5, 0, 10],
      ul: [...diagnosticosFam],
    },
    {
      text: `Cirurgias: `,
      fontSize: 15,
      margin: [25, 5, 0, 0],
    },
    {
      fontSize: 15,
      margin: [30, 5, 0, 10],
      ul: [...cirurgiasFam],
    },
    {
      text: `Orientações/Conduta:`,
      fontSize: 15,
      bold: true,
      margin: [15, 10, 0, 10],
    },
    {
      ul: [relatorio?.conduta],
      fontSize: 15,
      margin: [20, 10, 0, 10],
    },
    {
      text: `CID: `,
      fontSize: 15,
      bold: true,
      margin: [15, 10, 0, 0],
    },

    {
      fontSize: 15,
      margin: [20, 10, 0, 10],
      ul: [...cids],
    },
  ];

  function Rodape(currentPage: any, pageCount: any) {
    return [
      {
        text: `${currentPage} / ${pageCount}`,
        alignment: 'right',
        fontSize: 9,
        margin: [0, 10, 20, 0],
      },
    ];
  }

  const rodape: any = [];

  const docDefinitios: any = {
    pageSize: 'A4',
    pageMargins: [15, 50, 15, 40],

    header: [reportTitle],
    content: [details],
    footer: Rodape,
  };

  pdfMake.createPdf(docDefinitios).print({});

  // <div id="imprimir">
  //   <ConsultationInfo />

  //   <Text mb="6" color="brand-dark.200" fontSize="sm">
  //     <Text as="strong">CID: </Text>
  //     {/* {cids
  //             ? cids?.map((cid, key) => {
  //                 return key === cids.length - 1
  //                   ? `${cid.descricao}`
  //                   : `${cid.descricao} | `;
  //               })
  //             : null} */}
  //   </Text>
  //   <Text mb="6" color="brand-dark.200" fontSize="sm">
  //     <Text as="strong">Última consulta: </Text>
  //     {relatorio.dataAgendamento}
  //   </Text>
  //   <Text mb="6" color="brand-dark.200" fontSize="sm">
  //     <Text as="strong">Conduta: </Text>
  //     {relatorio.conduct}
  //     {/* {patient?.resource.acao} */}
  //   </Text>
  //   <Text mb="6" color="brand-dark.200" fontSize="sm">
  //     <Text as="strong">Orientações: </Text>
  //     {relatorio.orientation}
  //     {/* {patient?.resource.atender} */}
  //   </Text>
  //   <Text mb="6" color="brand-dark.200" fontSize="sm">
  //     <Text as="strong">Medicamentos: </Text>
  //     Medicamentos
  //     {/* {patient?.resource.medicamentos} */}
  //   </Text>
  // </div>;
}

export default PrintPdf;
