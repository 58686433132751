import { extendTheme } from '@chakra-ui/react';

const colors = {
  'brand-blue': {
    default: 'hsl(205, 100%, 53%)',
    darkened: 'hsl(205, 100%, 43%)',
  },
  'brand-green': {
    default: 'hsl(169, 43%, 56%)',
    lightened: 'hsl(155, 29%, 92%)',
    darkened: 'hsl(154, 28%, 67%)',
  },
  'brand-orange': {
    default: 'hsl(20, 83%, 60%)',
    darkened: 'hsl(20, 83%, 40%)',
  },
  'brand-red': {
    default: 'hsl(4, 90%, 58%)',
    darkened: 'hsl(4, 90%, 38%)',
  },
  text: {
    default: '#707070',
    lightened: '#959595',
    darkened: '#454545',
  },
};

const fonts = {
  body: 'Montserrat, sans-serif',
  heading: 'Montserrat, sans-serif',
};

const styles = {
  global: {
    body: {
      bg: '#fafafb',
    },
  },
};

export const theme = extendTheme({
  colors,
  fonts,
  styles,
});
