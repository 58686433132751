import { useQuery } from 'react-query';

import { useQueryParams } from '@/hooks';
import { api } from '@/lib';
import { GetHeaderInfoParams, GetHeaderInfoParamsResponse } from '@/types';

async function getHeaderInfo({
  consultationId,
  patientId,
  scheduleId,
  userId,
}: GetHeaderInfoParams) {
  const { data } = await api.get<GetHeaderInfoParamsResponse>(
    `/tele-consulta?UsuarioId=${userId}&PacienteId=${patientId}&ConsultaId=${consultationId}&AgendaId=${scheduleId}`,
  );

  const consulta = await api.get(`/consulta/${consultationId}`);

  return {
    medic: {
      avatar: data.data.usuario.urlFoto,
      name: data.data.usuario.nome,
      speciality: data.data.usuario.especialidadePadrao,
    },
    patient: {
      id: patientId,
      avatar: data.data.paciente.urlFoto,
      name: data.data.paciente.nome,
      age: data.data.paciente.idade,
      gender: data.data.paciente.genero,
    },
    urlVideo: data.data.urlVideo,
    schedule: {
      date: data.data.agenda.data,
      type: consulta.data.data.tipoConsulta,
      hour: data.data.agenda.horario,
      idHorasAgenda: data.data.agenda.idHorasAgenda,
    },
    consultaStatus: consulta.data.data.status,
  };
}

export function useHeaderInfoQuery() {
  const params = useQueryParams();

  const consultationId = params?.ConsultaId as string;
  const patientId = params?.PacienteId as string;
  const scheduleId = params?.AgendaId as string;
  const userId = params?.UsuarioId as string;

  const query = useQuery(
    ['headerInfo', consultationId, patientId, scheduleId, userId],
    () => getHeaderInfo({ consultationId, patientId, scheduleId, userId }),
    {
      enabled: !!params,
    },
  );

  return query;
}
