import { ReactNode } from 'react';

import { ReactQueryProvider } from './ReactQueryProvider';
import { UIProvider } from './UIProvider';

interface ProviderProps {
  children: ReactNode;
}

export function Provider({ children }: ProviderProps) {
  return (
    <ReactQueryProvider>
      <UIProvider>{children}</UIProvider>
    </ReactQueryProvider>
  );
}
