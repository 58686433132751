import { api } from '@/lib';
import { useToast } from '@chakra-ui/react';
import { useQueryParams } from '../utils';

type endConsulta = {
  idHorasAgenda?: string;
  statusConsulta: number;
};

async function endConsultation(dados: endConsulta) {
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const data = await api
    .put(
      '/consulta/consulta-status',
      {},
      {
        params: {
          dados,
        },
      },
    )
    .then(res => {
      toast({ title: 'Consulta finalizada', status: 'success' });
    })
    .catch(err => {
      toast({ title: err.response.data.errors[0], status: 'error' });
    });
}

export async function getStatusConsultation() {
  const params = useQueryParams();

  const consultationId = params?.ConsultaId as string;
  const { data } = await api.get(`/consulta/${consultationId}`);

  return data;
}

export default endConsultation;
