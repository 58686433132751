import { useMutation } from 'react-query';

import { useQueryParams } from '@/hooks';
import { api } from '@/lib';
import { PatchScheduleParams } from '@/types';
import { useToast } from '@chakra-ui/react';

async function patchSchedule(data: any) {
  // eslint-disable-next-line no-console
  // console.log(JSON.stringify(data, null, 2));

  const dados = await api.patch('/anamnese', data);
}

export function useScheduleMutation() {
  const params = useQueryParams();

  const toast = useToast({
    duration: 4000,
    isClosable: true,
    position: 'top-right',
  });

  const mutation = useMutation(
    (data: any) =>
      patchSchedule({
        ...data,
        condutaOrientacao: {
          tipoLocalOrientacao: 0,
          pacienteId: params?.PacienteId as string,
          profissionalId: params?.UsuarioId as string,
          descricao: data.conduta,
        },
        usuarioId: params?.UsuarioId as string,
        pacienteId: params?.PacienteId as string,
        consultaId: params?.ConsultaId as string,
      }),
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Consulta salva com sucesso!',
        });
      },
      onError: () => {
        toast({
          status: 'error',
          title: 'Falha ao salvar consulta.',
        });
      },
    },
  );

  return mutation;
}
