import { useEffect, useState } from 'react';

type Params = {
  UsuarioId: string;
  PacienteId: string;
  AgendaId: string;
  ConsultaId: string;
};

export function useQueryParams() {
  const [params, setParams] = useState<Params | null | undefined>(undefined);

  useEffect(() => {
    const rawParams = new URLSearchParams(window.location.search);
    const parsedParams = Object.fromEntries(rawParams.entries()) as Params;

    /* Caso algum parâmetro não exista, null será retornado e
       e a exibição do portal é bloqueada */
    if (
      !parsedParams.AgendaId ||
      !parsedParams.ConsultaId ||
      !parsedParams.PacienteId ||
      !parsedParams.UsuarioId
    ) {
      setParams(null);
    } else {
      setParams(parsedParams);
    }
  }, []);

  return params;
}
