import { useGetConsultas } from '@/hooks/mutations/getConsultas';
import { MONTHS } from '@/types/constants';
import {
  IoDocumentTextOutline,
  IoCopyOutline,
  IoArrowUpOutline,
  IoArrowDownOutline,
} from 'react-icons/io5';
import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Icon,
  Input,
  InputLeftAddon,
  InputGroup,
  Image,
  Flex,
} from '@chakra-ui/react';
import { useHeaderInfoQuery } from '@/hooks';
import { useEffect, useMemo, useState } from 'react';
import iaImg from '@/assets/svgs/ia_icon.svg';
import { ExpandIcon, PrecriptionIcon } from '@/assets/svgs';
import InputAnamneseCard from './InputAnamneseCard';
import { Prescription } from '../PatientForm/Prescription';

export default function AnamneseCard({ expand, setExpand }: any) {
  const { data } = useHeaderInfoQuery();

  const date = new Date(data?.schedule.date ?? '').toLocaleDateString('pt-BR');

  const [consultas, setConsultas] = useState<any[]>();
  const [anamnese, setAnamnese] = useState<any>();
  const [consultasForm, setConsultasForm] = useState<any[]>();
  const { mutate: getConsults, isLoading } = useGetConsultas();

  const tabsTitles = useMemo(
    () => [
      { icon: 1, name: 'ANAMNESE' },
      { icon: 2, name: 'PRESCRIÇÃO' },
    ],
    [],
  );

  const getDateMonth = (num: number) => {
    const name = MONTHS.filter((m: any) => m.numeric === num)[0];

    return name.numeric;
  };

  const getCategories = () => {
    if (consultas) {
      const c = consultas?.reduce((acc, item, i) => {
        return {
          ...acc,
          [getDateMonth(new Date(item.dataPedido).getMonth())]: [
            ...(acc[getDateMonth(new Date(item.dataPedido).getMonth())] ?? []),
            item,
          ],
        };
      }, {});
      setConsultasForm(c);
    }
  };

  useEffect(() => {
    getCategories();
  }, [consultas]);

  useEffect(() => {
    // console.log('textCard', data?.schedule.date);
  }, [data]);

  return (
    <Tabs variant="unstyled" height="100%">
      <TabList w="100%" alignItems="center">
        {tabsTitles.map(tabTitle => (
          <Box
            backgroundColor="#fff"
            mr="5px"
            borderTopRadius="15"
            sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
          >
            <Tab
              py="16px"
              px="24px"
              pr="24px"
              key={tabTitle.name}
              color="#807D7D"
              bg="#ddd"
              fontSize="lg"
              fontWeight="400"
              textTransform="uppercase"
              h="100%"
              w="100%"
              _selected={{
                bg: '#fff',
                color: '#3E3E3E',
                fontWeight: '600',
              }}
              fontFamily="Rubik"
              borderTopRadius="15"
              alignItems="center"
            >
              {tabTitle.icon === 1 ? (
                <Icon
                  as={IoDocumentTextOutline}
                  fontSize="24px"
                  marginRight={5}
                />
              ) : (
                <Image
                  width="20px"
                  height="auto"
                  marginRight="5"
                  src={PrecriptionIcon}
                />
              )}

              {tabTitle.name}
            </Tab>
          </Box>
        ))}
        <Box
          height="100%"
          width="100%"
          display="flex"
          justifyContent="flex-end"
        >
          <Image
            width="20px"
            height="auto"
            fontSize="30px"
            marginLeft={5}
            marginRight="2"
            cursor="pointer"
            onClick={() => {
              if (expand === 4) {
                setExpand(0);
              } else {
                setExpand(4);
              }
            }}
            src={ExpandIcon}
          />
        </Box>
      </TabList>

      <Box
        w="100%"
        h="auto"
        paddingBottom="20"
        backgroundColor="#fff"
        borderBottomRadius="15"
        borderRightRadius="15"
      >
        <TabPanels h={expand === 4 ? '100%' : 'auto'}>
          <TabPanel p="20px" h="100%" backgroundColor="#fff" borderRadius="15">
            <Text
              fontSize="sm"
              w="100%"
              color="#3D3D3D"
              fontFamily="Rubik"
              paddingBottom="19"
            >
              CONSULTA ATUAL: {date}
            </Text>
            <Flex alignItems="center" mb="19">
              <Image width="55px" height="auto" src={iaImg} alt="Logo da IA" />
              <InputGroup ml="30">
                <InputLeftAddon
                  backgroundColor="#fff"
                  color="#60C2B0"
                  fontFamily="Rubik"
                  borderRadius="40%"
                >
                  I.A.
                </InputLeftAddon>
                <Input
                  color="#000"
                  type="text"
                  placeholder="Faça uma pergunta"
                  onChange={() => {}}
                  fontFamily="Rubik"
                  borderRadius="20"
                />
              </InputGroup>
            </Flex>

            <InputAnamneseCard />
          </TabPanel>
          <TabPanel p="20px" maxHeight="100%" minHeight="100%" color="#000">
            <Prescription expand={expand} />
          </TabPanel>
        </TabPanels>
      </Box>
    </Tabs>
  );
}
