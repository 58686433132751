import { useGetConsultas } from '@/hooks/mutations/getConsultas';
import { MONTHS } from '@/types/constants';
import {
  Box,
  VStack,
  Flex,
  Grid,
  GridItem,
  Icon,
  Text,
  InputLeftAddon,
  InputGroup,
  Image,
  Input,
  CircularProgress,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  IoAddCircleOutline,
  IoArrowForward,
  IoEllipseOutline,
  IoRemoveOutline,
} from 'react-icons/io5';
import iaImg from '@/assets/svgs/ia_icon.svg';
import { ExpandIcon, LineIcon } from '@/assets/svgs';
import InputAnamneseCard from '../AnamneseCard/InputAnamneseCard';
import RadioCard from './RadioCard';

type HistoryProps = {
  paciente?: any;
  expand?: number;
  setExpand?: any;
};

export default function HistoryHorizCard({
  paciente,
  expand,
  setExpand,
}: HistoryProps) {
  const [consultas, setConsultas] = useState<any[]>();
  const [consultasForm, setConsultasForm] = useState<any[]>();
  const [selectConsulta, setSelectConsulta] = useState(null);
  const { mutate: getConsults, isLoading } = useGetConsultas();

  const getConsultas = async () => {
    getConsults(paciente?.id, {
      onSuccess: (response: any) => {
        setConsultas(response);
      },
    });
  };

  useEffect(() => {
    getConsultas();
  }, [paciente]);

  const getDateMonth = (num: number) => {
    const name = MONTHS.filter((m: any) => m.numeric === num)[0];

    return name.numeric;
  };

  const getCategories = () => {
    if (consultas) {
      const c = consultas?.reduce((acc, item, i) => {
        return {
          ...acc,
          [getDateMonth(new Date(item.horasAgenda.agenda.data).getMonth())]: [
            ...(acc[
              getDateMonth(new Date(item.horasAgenda.agenda.data).getMonth())
            ] ?? []),
            item,
          ],
        };
      }, {});

      setConsultasForm(c);
    }
  };

  useEffect(() => {
    getCategories();
  }, [consultas]);

  const backConsulta = () => {
    setSelectConsulta(null);
  };

  return (
    <>
      <Text
        backgroundColor="#fff"
        fontSize="lg"
        w="100%"
        color="#3E3E3E"
        fontFamily="Rubik"
        padding="5"
        borderTopRadius="15"
        display="flex"
        alignItems="center"
      >
        HISTÓRICO
        <Box width="100%" display="flex" justifyContent="flex-end">
          <Image
            width="20px"
            fontSize="30px"
            marginRight="0"
            cursor="pointer"
            onClick={() => {
              if (expand === 2) {
                setExpand(0);
              } else {
                setExpand(2);
              }
            }}
            src={ExpandIcon}
          />
        </Box>
      </Text>

      <Flex
        backgroundColor="#fff"
        direction="row"
        maxW="100%"
        w="100%"
        h="auto"
        borderBottomRadius="15"
        paddingTop="50"
        paddingBottom="50"
        overflowX="auto"
        sx={{
          '&::-webkit-scrollbar': { width: '6px', height: '10px' },
          '&::-webkit-scrollbar-track': { borderRadius: '8px' },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '15',
            bg: '#bfbfbf',
          },
        }}
      >
        {isLoading ? (
          <Box
            display="flex"
            alignContent="center"
            justifyContent="center"
            width="100%"
          >
            <CircularProgress value={30} size="120px" isIndeterminate />
          </Box>
        ) : !selectConsulta ? (
          <>
            (
            {MONTHS.map((m: any) => {
              const { name } = m;
              return (
                <Flex
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  borderTop="1px solid #E4E4E4"
                  justifyContent="center"
                  style={{ height: 'fit-content', width: 'auto' }}
                >
                  {consultasForm && consultasForm[m.numeric] && (
                    <Flex
                      direction="column"
                      alignItems="center"
                      width="auto"
                      wrap="wrap"
                      backgroundColor="#fff"
                      paddingLeft="30"
                      paddingRight="30"
                      marginTop="-60px"
                    >
                      <Icon
                        as={IoRemoveOutline}
                        color="#D6D6D6"
                        fontSize="60px"
                        marginTop="-15px"
                        style={{
                          transform: 'rotate(90deg)',
                        }}
                      />
                      <Text
                        color="#0005"
                        fontSize="lg"
                        fontWeight="medium"
                        fontFamily="Rubik"
                        paddingLeft="30"
                        paddingRight="30"
                      >
                        {name}
                      </Text>
                    </Flex>
                  )}

                  {consultasForm &&
                    consultasForm[m.numeric]?.map((c: any) => {
                      const date = new Date(
                        c.horasAgenda.agenda.data ?? '',
                      ).toLocaleDateString('pt-BR');

                      return (
                        <Flex
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          width="auto"
                          wrap="wrap"
                          backgroundColor="#fff"
                          paddingLeft="30"
                          paddingRight="30"
                          cursor="pointer"
                          onClick={() => setSelectConsulta(c)}
                        >
                          <Icon
                            as={IoEllipseOutline}
                            color="#D6D6D6"
                            fontSize="30px"
                            marginTop="-20px"
                            backgroundColor="#fff"
                          />
                          <Icon
                            as={IoRemoveOutline}
                            color="#D6D6D6"
                            fontSize="60px"
                            marginTop="-15px"
                            style={{
                              transform: 'rotate(90deg)',
                            }}
                          />
                          <Text
                            color="#000"
                            fontSize="lg"
                            fontWeight="large"
                            fontFamily="Rubik"
                            width="max-content"
                            paddingTop="10"
                          >
                            {c.especialidade.length > 0
                              ? c.especialidade[0].descricao
                              : c.conselho[0].descricao}
                          </Text>
                          <Text
                            color="#0005"
                            fontSize="xs"
                            fontWeight="normal"
                            fontFamily="Rubik"
                          >
                            {date}
                          </Text>
                        </Flex>
                      );
                    })}
                </Flex>
              );
            })}
            )
          </>
        ) : (
          <Box p="20px" h="100%">
            <Flex alignItems="center" mb="19">
              <Image width="55px" height="auto" src={iaImg} alt="Logo da IA" />
              <InputGroup ml="30">
                <InputLeftAddon
                  backgroundColor="#fff"
                  color="#60C2B0"
                  fontFamily="Rubik"
                  borderRadius="40%"
                >
                  I.A.
                </InputLeftAddon>
                <Input
                  color="#000"
                  type="text"
                  placeholder="Faça uma pergunta"
                  onChange={() => {}}
                  fontFamily="Rubik"
                  borderRadius="20"
                />
              </InputGroup>
            </Flex>
            <RadioCard dados={selectConsulta} onPress={backConsulta} />
          </Box>
        )}
      </Flex>

      {!selectConsulta && (
        <Flex alignItems="center" mb="19" mt="19">
          <Image width="55px" height="auto" src={iaImg} alt="Logo da IA" />
          <InputGroup ml="30">
            <InputLeftAddon
              backgroundColor="#fff"
              color="#60C2B0"
              fontFamily="Rubik"
              borderRadius="40%"
            >
              I.A.
            </InputLeftAddon>
            <Input
              color="#000"
              type="text"
              placeholder="Faça uma pergunta"
              onChange={() => {}}
              fontFamily="Rubik"
              borderRadius="20"
            />
          </InputGroup>
        </Flex>
      )}
    </>
  );
}
